import React ,{useState,useEffect} from 'react';
import { useTheme } from '../ThemeContext/ThemeContext';

import { Link } from 'react-router-dom';
import p1 from "../../assets/porteghal.jpg";
import p2 from "../../assets/hendune.png";
import p3 from "../../assets/albaloo.png";
import p4 from "../../assets/talebi.png";
import p5 from "../../assets/ananas.png";
import p6 from "../../assets/but.png";
import p7 from "../../assets/shir3.png";
import './Products.css';


const products = [
    { id: 1, img: p1, title: 'اسانس پرتقال', description: 'اسانس پرتقال از جمله اسانس های با پایه طبیعی محسوب می شود. این اسانس ها معمولا از روغن های طبیعی تشکیل شده و علاوهبر عطر و رایحه خواص طبیعی را نیز انتقال می دهند.اسانس پرتقال بر اساس نوع روغن استفاده شده دارای طعم های و پروفایل های مختلفی است. این پروفایل ها شامل اسانس پرتقالوالنسیا، پرتقال تلخ و پوستی، پرتقال گوشتی و پرتقال شیرین می باشد.اسانس پرتقال دارای کاربرد های مختلف است. این اسانس در انواع کیک و کلوچه، ویفر و بیسکوییت، تافی و آبنبات و انواعنوشیدنی کاربرد دارد. البته از دیگر استفاده های آن می توان به استفاده در عنوان تنباکو نیز اشاره کرد.' },
    { id: 2, img: p2, title: 'اسانس هندوانه ', description: 'اسانس هندوانه خوراکی یکی از محصولات محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم دلپذیرش، مورد توجهقرار گرفته است. این اسانس نه تنها طعمی شگفت‌انگیز به غذاها و نوشیدنی‌ها می‌بخشد، بلکه خواص سلامتی زیادی نیز دارد. دراین مقاله، به بررسی خواص، کاربردها و مزایای اسانس هندوانه می‌پردازیم.' },
    { id: 3, img: p3, title: 'اسانس البالو ', description: 'اسانس آلبالو خوراکی یکی از طعم‌دهنده‌های محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم خاص خود، موردتوجه بسیاری قرار گرفته است. این اسانس نه تنها به غذاها و دسرها طعمی شگفت‌انگیز می‌بخشد، بلکه خواص سلامتی بسیاری نیزدارد. در این مقاله، به بررسی ویژگی‌ها، کاربردها و مزایای اسانس آلبالو می‌پردازیم.' },
    { id: 4, img: p4, title: 'اسانس طالبی ', description: 'اسانس «طالبی» با عطر و طعم طبیعی و خاص خود، گزینه‌ای عالی برای استفاده در صنایع غذایی و بهداشتی است. این اسانس در تولید محصولات متنوعی مانند نوشیدنی‌ها، دسرها، شیرینی‌جات و محصولات آرایشی و بهداشتی کاربرد دارد و رایحه‌ای طبیعی' },
    { id: 5, img: p5, title: 'اناناس', description: 'محصول «اناناس» یکی از انواع اسانس‌های میوه‌ای است که به دلیل رایحه و طعم خاص، کاربردهای گسترده‌ای در صنایع غذایی و بهداشتی دارد. اسانس اناناس برای تولید محصولات متنوعی مانند نوشیدنی‌ها، شیرینی‌جات، دسرها، و محصولات آرایشیبهداشتی مورد استفاده قرار می‌گیرد و به این محصولات، رایحه‌ای گرمسیری و طبیعی می‌بخشد' },
{ id: 6, img: p6, title: ' اسانس کره',   description: 'اسانس کره از جمله اسانس‌های پرمصرف در صنایع غذایی است. این اسانس در صنایع لبنی مانند کره، بستنی و پنیر پیتزا و در صنایع کیک و شیرینی و تافی کاربرد دارد. رایحه این اسانس در کره‌های گیاهی و حیوانی گرم و شیرین است و در پنیر پیتزا تیز و ترش و در بستنی شیرین و ملایم‌تر می‌باشد. در صنایع کیک و شیرینی، این اسانس دارای مقاومت حرارتی مختلفی است و در تولید تافی‌ها نیز با مقاومت حرارتی مطلوب کاربرد دارد.'}
   , { id: 7, img: p7, title: 'شیر کندانسه', description:"اسانس شیرکندانسه یکی از اسانس های خاص و پرمصرف در صنایع غذایی شناخته می شود. این اسانس با توجه به مصارف مختلفو استانداردهای متفاوت، دارای تنوع زیادی در بین مشتریان می باشد. اسانس شیر تازه و شیر پخته نیز می باشد." }
];

export function Products  ()  {
      const { isDarkMode } = useTheme(); // استفاده از context
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    // تنظیم تم بر اساس isDarkMode
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
  }} ,[isDarkMode]); 
    return (
        <div className="products-container">
            <h2>محصولات ما</h2>
            <div className="products-grid">
                {products.map((product) => (
                    <div className="product-card" key={product.id}>
                        <img src={product.img} alt={product.title} />
                        <h3>{product.title}</h3>
                        <p>{product.description}</p>
                        <Link to={`/mahsulat/${product.id}`}>
                            <button className='view-all-button'>جزئیات محصول</button>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Products;
