import React ,{useEffect,useState}from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import p1 from "../../assets/porteghal.jpg";
import p2 from "../../assets/hendune.png";
import p3 from "../../assets/albaloo.png";
import p4 from "../../assets/talebi.png";
import p5 from "../../assets/ananas.png";
import p6 from "../../assets/but.png";
import p7 from "../../assets/shir3.png";
import { useTheme } from '../ThemeContext/ThemeContext';
import './Mahulat.css';

const products = [
    { id: 1, img: p1, title: 'اسانس پرتقال', description: 'اسانس پرتقال از جمله اسانس های با پایه طبیعی محسوب می شود. این اسانس ها معمولا از روغن های طبیعی تشکیل شده و علاوهبر عطر و رایحه خواص طبیعی را نیز انتقال می دهند.',metaDescription:"اسانس پرتقال دارای کاربرد های مختلف است. این اسانس در انواع کیک و کلوچه، ویفر و بیسکوییت، تافی و آبنبات و انواعنوشیدنی کاربرد دارد. البته از دیگر استفاده های آن می توان به استفاده در عنوان تنباکو نیز اشاره کرد." ,
        body:(
            <div class="description-box">
                <h3>معرفی</h3>
                <p>اسانس پرتقال از جمله اسانس های با پایه طبیعی محسوب می شود. این اسانس ها معمولا از روغن های طبیعی تشکیل شده و علاوه
بر عطر و رایحه خواص طبیعی را نیز انتقال می دهند.
اسانس پرتقال بر اساس نوع روغن استفاده شده دارای طعم های و پروفایل های مختلفی است. این پروفایل ها شامل اسانس پرتقال
والنسیا، پرتقال تلخ و پوستی، پرتقال گوشتی و پرتقال شیرین می باشد.
اسانس پرتقال دارای کاربرد های مختلف است. این اسانس در انواع کیک و کلوچه، ویفر و بیسکوییت، تافی و آبنبات و انواع
نوشیدنی کاربرد دارد. البته از دیگر استفاده های آن می توان به استفاده در عنوان تنباکو نیز اشاره کرد.</p>
                <h3>محصولات شیرینی و آردی</h3>
                <p>اسانس استفاده شده در این محصولات معمولا دارای مقاومت بالایی دارد. از نظر پروفایلی نیز پروفایل والنسیا و شیرین برای این
محصول مناسب است. اما هر چه محصول نهایی دارای آب کمتری باشد مقاومت اسانس به کار رفته باید بیشتر باشد. بطور مثال
اسانس پرتقال در بیکوییت به مراتب مقاومت بیشتری نسبت به اسانس استفاده شده در کیک و کلوچه دارد. البته از نظر فنی مانعی
برای استفاده مشترک در همه محصولات وجود ندارد. اما توجه به چنین نکاتی قیمت تمام شده را کاهش می دهد.</p>
                <h3>محصولات تافی و آبنبات</h3>
                <p>با توجه به نوع محصولات استفاده از نوع اسانس پرتقال متفاوت است. برای محصولاتی با پایه روغنی استفاده از اسانس روغنه
پیشنهاد می شود. اما برای اسانس هایی با پایه آب همیشه نیاز به اسانس هایی با حلالیت مناسب در آب نیاز دارید.</p>
            </div>
        )},
    { id: 2, img: p2, title: 'اسانس هندوانه', description: "اسانس هندوانه خوراکی یکی از محصولات محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم دلپذیرش، مورد توجه زیادی نیز داردر" , metaDescription:"اسانس هندوانه از میوه‌ی خوشمزه و آبدار هندوانه استخراج می‌شود و دارای خواص آنتی‌اکسیدانی و ضد التهابی است. این اسانس",
        body:(
            <div class="description-box">
                <h2>اسانس هندوانه خوراکی: طعم و عطر تابستان در هر قطره</h2>
                <h3>مقدمه</h3>
                <p>اسانس هندوانه خوراکی یکی از محصولات محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم دلپذیرش، مورد توجه
قرار گرفته است. این اسانس نه تنها طعمی شگفت‌انگیز به غذاها و نوشیدنی‌ها می‌بخشد، بلکه خواص سلامتی زیادی نیز دارد. در
این مقاله، به بررسی خواص، کاربردها و مزایای اسانس هندوانه می‌پردازیم.</p>
                <h3>خواص اسانس هندوانه</h3>
                <p>اسانس هندوانه از میوه‌ی خوشمزه و آبدار هندوانه استخراج می‌شود و دارای خواص آنتی‌اکسیدانی و ضد التهابی است. این اسانس
غنی از ویتامین C و ویتامین A بوده و می‌تواند به تقویت سیستم ایمنی بدن کمک کند. همچنین، هندوانه حاوی مقدار زیادی آب است
که به هیدراته نگه داشتن بدن کمک می‌کند.</p>
<h3>کاربردهای اسانس هندوانه</h3>
<p>اسانس هندوانه در تولید انواع نوشیدنی‌ها، دسرها و خوراکی‌ها کاربرد دارد. از جمله نوشیدنی‌های محبوبی که با این اسانس تهیه
می‌شوند، می‌توان به اسموتی‌ها، آبمیوه‌ها و نوشیدنی‌های گازدار اشاره کرد. همچنین، در صنعت شیرینی‌سازی، این اسانس به
عنوان طعم‌دهنده در کیک‌ها، شیرینی‌ها و بستنی‌ها استفاده می‌شود.</p>
<h3>مزایای اسانس هندوانه</h3>
<p>
1. طعم و عطر مطبوع: اسانس هندوانه عطر و طعمی شبیه به میوه‌ی تازه آن دارد و می‌تواند حس و حال تابستان را به هر
غذایی اضافه کند.
2. خواص سلامتی: مصرف اسانس هندوانه می‌تواند به بهبود هیدراتاسیون و سلامت پوست کمک کند. همچنین،
آنتی‌اکسیدان‌های موجود در آن به مبارزه با رادیکال‌های آزاد کمک می‌کنند.
3. تنوع در استفاده: این اسانس به راحتی می‌تواند در انواع غذاها و نوشیدنی‌ها گنجانده شود، از سالادها گرفته تا دسرها و
اسموتی‌ها.</p>
<h3>نتیجه‌گیری</h3>
<p>اسانس هندوانه خوراکی یک گزینه‌ی عالی برای کسانی است که به دنبال افزودن طعمی تازه و خوشمزه به غذاها و نوشیدنی‌های
خود هستند. با خواص متعدد سلامتی و عطر و طعم دلپذیر، این اسانس می‌تواند به یک انتخاب محبوب در آشپزخانه تبدیل شود. اگر
به دنبال یک تجربه‌ی جدید در طعم‌دهی به غذاهای خود هستید، اسانس هندوانه</p>
            </div>
        )
    },
    { id: 3, img: p3, title: 'اسانس البالو ', description: "آلبالو خوراکی یکی از طعم‌دهنده‌های محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم خاص خود،خریداسانس البالو  " , metaDescription:"اسانس آلبالو در تولید انواع نوشیدنی‌ها، دسرها و خوراکی‌ها استفاده می‌شود. از جمله نوشیدنی‌های محبوبی که با این اسانس تهیهمی‌شوند، می‌توان به آبمیوه‌ها، اسموتی‌ها و نوشیدنی‌های گازدار اشاره کرد. همچنین، در صنعت شیرینی‌سازی، این اسانس بهعنوان طعم‌دهنده در کیک‌ها، شیرینی‌ها و بستنی‌ها مورد استفاده قرار می‌گیرد.",
        body:(
            <div class="description-box">

                <h2>اسانس آلبالو خوراکی: طعمی دلپذیر و خواص بی‌نظیر</h2>
                <h3>مقدمه</h3>
                <p>اسانس آلبالو خوراکی یکی از طعم‌دهنده‌های محبوب در صنعت غذا و نوشیدنی است که به دلیل عطر و طعم خاص خود، مورد
توجه بسیاری قرار گرفته است. این اسانس نه تنها به غذاها و دسرها طعمی شگفت‌انگیز می‌بخشد، بلکه خواص سلامتی بسیاری نیز
دارد. در این مقاله، به بررسی ویژگی‌ها، کاربردها و مزایای اسانس آلبالو می‌پردازیم.</p>
<h3>خواص اسانس آلبالو</h3>
<p>اسانس آلبالو از میوه‌ی خوشمزه و آبدار آلبالو استخراج می‌شود و سرشار از ویتامین‌ها و آنتی‌اکسیدان‌هاست. این اسانس حاوی
ویتامین C و ترکیبات ضد التهابی است که می‌تواند به تقویت سیستم ایمنی بدن و کاهش التهابات کمک کند. همچنین، آلبالو به عنوان
یک منبع خوب فیبر شناخته می‌شود که می‌تواند به بهبود عملکرد گوارش کمک کند.</p>
<h2>کاربردهای اسانس آلبالو</h2>
<p>اسانس آلبالو در تولید انواع نوشیدنی‌ها، دسرها و خوراکی‌ها استفاده می‌شود. از جمله نوشیدنی‌های محبوبی که با این اسانس تهیه
می‌شوند، می‌توان به آبمیوه‌ها، اسموتی‌ها و نوشیدنی‌های گازدار اشاره کرد. همچنین، در صنعت شیرینی‌سازی، این اسانس به
عنوان طعم‌دهنده در کیک‌ها، شیرینی‌ها و بستنی‌ها مورد استفاده قرار می‌گیرد.</p>
<h2>مزایای اسانس آلبالو</h2>
<p>1. عطر و طعم منحصر به فرد: اسانس آلبالو عطر و طعمی مشابه میوه‌ی تازه آلبالو دارد و می‌تواند به راحتی حس و حال
تازگی را به هر غذایی اضافه کند.
2. خواص سلامتی: مصرف اسانس آلبالو می‌تواند به بهبود خواب و کاهش استرس کمک کند. این اسانس به دلیل دارا بودن
ملاتونین، می‌تواند به خواب بهتر و بهبود کیفیت خواب کمک کند.
3. تنوع در استفاده: اسانس آلبالو به راحتی در انواع غذاها و نوشیدنی‌ها گنجانده می‌شود. می‌توانید آن را به سالادها،دسرها و حتی سس‌ها اضافه کنید.</p>
         <h2>نکات مهم در خرید اسانس آلبالو</h2>
         <p>هنگام خرید اسانس آلبالو، به کیفیت محصول توجه کنید. بهتر است از برندهای معتبر و شناخته‌شده خرید کنید تا از خلوص و کیفیت
اسانس اطمینان حاصل کنید. همچنین، حتماً به برچسب محصول توجه کنید و از محتوای شیمیایی آن مطلع شوید.</p>
<h2>نتیجه‌گیری</h2>
<p>اسانس آلبالو خوراکی یک انتخاب عالی برای افزودن طعمی تازه و لذت‌بخش به غذاها و نوشیدنی‌های شماست. با خواص متعددسلامتی و عطر و طعم دلپذیر، این اسانس می‌تواند به یک گزینه محبوب در آشپزخانه تبدیل شود. اگر به دنبال تجربه‌ای جدید در
طعم‌دهی به غذاهای خود هستید، اسانس آلبالو را امتحان کنید و از لذت آن بهره‌مند شوید!</p>
            </div>
        )},
   {
  id: 4,
  img: p4, // مسیر تصویر محصول، مثلا '/images/p6.jpg'
  title: 'طالبی',
  description: 'توضیحات کامل محصول طالبی',
  metaDescription: "اسانس طالبی با عطر و طعم طبیعی مناسب برای صنایع غذایی و محصولات آرایشی، افزاینده جذابیت محصولات با رایحه گرمسیری.",
  body: (
    <div class="description-box">
      <h3>معرفی محصول اسانس طالبی</h3>
      <p>اسانس «طالبی» با عطر و طعم طبیعی و خاص خود، گزینه‌ای عالی برای استفاده در صنایع غذایی و بهداشتی است. این اسانس در تولید محصولات متنوعی مانند نوشیدنی‌ها، دسرها، شیرینی‌جات و محصولات آرایشی و بهداشتی کاربرد دارد و رایحه‌ای طبیعی و گرمسیری به این محصولات اضافه می‌کند.</p>

      <h3>ویژگی‌های اسانس طالبی</h3>
      <p><strong>پروفایل طعم و عطر متمایز:</strong> اسانس طالبی با طعم شیرین و خاص خود، حس طراوت و تازگی را به محصولات اضافه کرده و تجربه‌ای خوشایند برای مصرف‌کننده فراهم می‌آورد.</p>
      <p><strong>پایداری حرارتی:</strong> این اسانس در برابر دما مقاوم بوده و در فرآیندهای حرارتی مانند پخت و پز یا تهیه نوشیدنی‌های گرم، کیفیت و رایحه‌ی خود را حفظ می‌کند.</p>
      <p><strong>کاربردهای گسترده:</strong> اسانس طالبی در محصولات غذایی مانند نوشیدنی‌ها، دسرها، و شیرینی‌جات و همچنین در محصولات مراقبتی و آرایشی مورد استفاده قرار می‌گیرد که به آن‌ها عطر و طعم گرمسیری و دلپذیر می‌بخشد.</p>

      <h3>مزایای استفاده از اسانس طالبی</h3>
      <p><strong>تقویت عطر و طعم طبیعی:</strong> این اسانس به‌طور طبیعی عطر و طعم طالبی را به محصولات افزوده و جذابیت آن‌ها را برای مصرف‌کنندگان بیشتر می‌کند.</p>
      <p><strong>سازگاری با محیط زیست:</strong> اسانس طالبی با رعایت استانداردهای طبیعی و ارگانیک تولید می‌شود و بدون مواد شیمیایی مضر است.</p>
      <p><strong>جذابیت برای مصرف‌کنندگان:</strong> اسانس طالبی به دلیل طعم و رایحه خوش‌بو و محبوب خود، انتخابی عالی برای تولیدکنندگانی است که به دنبال ارائه محصولات جذاب و خاص به مصرف‌کنندگان هستند.</p>

      <h3>نحوه خرید و استفاده از اسانس طالبی</h3>
      <p>برای خرید اسانس طالبی با کیفیت عالی و کسب اطلاعات بیشتر در مورد کاربردهای آن، می‌توانید با فروشگاه ما تماس بگیرید و از مشاوره کارشناسان ما بهره‌مند شوید. برای دریافت اطلاعات بیشتر، با شماره تماس ما در ارتباط باشید تا راهنمایی‌های لازم را دریافت کنید.</p>

      <p><strong>برچسب‌های مرتبط:</strong> اسانس طالبی، اسانس میوه‌ای، اسانس طبیعی، اسانس صنایع غذایی، اسانس دسر، اسانس آرایشی</p>
    </div>
  )
}
,
    {
  id: 5,
  img: p5, // مسیر تصویر محصول، مثلا '/images/p5.jpg'
  title: 'اناناس',
  description: 'توضیحات کامل محصول اناناس',
  metaDescription: "اسانس اناناس با طعم و رایحه گرمسیری، مناسب برای محصولات غذایی، نوشیدنی‌ها، دسرها، و محصولات آرایشی بهداشتی.",
  body: (
    <div class="description-box">
      <h3>معرفی محصول اسانس اناناس</h3>
      <p>محصول «اناناس» یکی از انواع اسانس‌های میوه‌ای است که به دلیل رایحه و طعم خاص، کاربردهای گسترده‌ای در صنایع غذایی و بهداشتی دارد. اسانس اناناس برای تولید محصولات متنوعی مانند نوشیدنی‌ها، شیرینی‌جات، دسرها، و محصولات آرایشی بهداشتی مورد استفاده قرار می‌گیرد و به این محصولات، رایحه‌ای گرمسیری و طبیعی می‌بخشد.</p>

      <h3>ویژگی‌های اسانس اناناس</h3><strong>پروفایل طعم و عطر متمایز:</strong> اسانس اناناس با طعم شیرین و دلپذیر خود، حس تازگی و شادابی را به محصولات اضافه می‌کند و تجربه لذت‌بخشی برای مصرف‌کننده به ارمغان می‌آورد.<strong>پایداری حرارتی:</strong> این اسانس مقاوم در برابر دما است و در فرآیندهای حرارتی مانند پخت و پز و تهیه نوشیدنی‌های گرم، کیفیت خود را حفظ می‌کند.<strong>کاربرد گسترده:</strong> از اسانس اناناس در انواع محصولات غذایی، نوشیدنی‌ها، شیرینی‌ها، دسرها، و همچنین محصولات مراقبتی و آرایشی استفاده می‌شود که به آن‌ها عطر و طعم گرمسیری و طبیعی می‌دهد.

      <h3>مزایای استفاده از اسانس اناناس</h3>
      
        <strong>تقویت عطر و طعم طبیعی:</strong> این اسانس به طور طبیعی، رایحه‌ی اناناس را در محصولاتی که ممکن است عطر و طعم کمتری داشته باشند، تقویت می‌کند.
        <strong>سازگاری با محیط زیست:</strong> اسانس اناناس با توجه به استانداردهای طبیعی و ارگانیک تولید می‌شود و بدون مواد شیمیایی مضر است.
        <strong>جذابیت برای مصرف‌کنندگان:</strong> اسانس اناناس به دلیل عطر و طعم محبوبش در بین مصرف‌کنندگان بسیار مورد استقبال قرار می‌گیرد و به تولیدکنندگان کمک می‌کند تا محصولات جذاب‌تری ارائه دهند.
      

      <h3>نحوه خرید و استفاده از اسانس اناناس</h3>
      <p>برای خرید اسانس اناناس باکیفیت و دریافت مشاوره درباره کاربردهای آن، می‌توانید به فروشگاه ما مراجعه کرده و با کارشناسان ما در ارتباط باشید. همچنین، در صورت نیاز به اطلاعات بیشتر، لطفاً با شماره تماس ما تماس حاصل کنید تا راهنمایی‌های لازم را دریافت نمایید.</p>

      <p><strong>برچسب‌های مرتبط:</strong> اسانس اناناس، اسانس میوه‌ای، اسانس طبیعی، اسانس صنایع غذایی، اسانس شیرینی‌جات، اسانس آرایشی</p>
    </div>
  )
}
,
    { 
        id: 6, 
        img: p6, 
        title: "اسانس کره", 
        description: 'خرید اسانس کره با بهترین کیفیت، مناسب برای صنایع لبنی، کیک و شیرینی‌پزی.',
        metaDescription: "اسانس کره با رایحه و طعم طبیعی، مناسب برای صنایع غذایی، لبنیات، کیک و شیرینی. خرید اسانس کره با کیفیت بالا و مقاوم در برابر حرارت.",
        body: (
            <div class="description-box">
                <h2>اسانس کره</h2>
                <p>

اسانس کره از جمله اسانس های پرمصرف در صنایع غذایی می باشد. این اسانس به طور عمده در صنایع مختلف مانند لبنی، کیک و
شیرینی و انواع تافی کاربرد گسترده دارد.                </p>
                
                <h2>کاربرد در محصولات لبنی</h2>
                <p>

اسانس کره در صنایع لبنی در انواع کره، بستنی و پنیر پیتزا کاربرد دارد. البته پروفایل رایحه این اسنس در زیرمجموعه های
مختلف تفاوت زیادی دارد.
رایحه این اسانس در انواع کره گیاهی و حیوانی پروفایلی گرم و شیرین می باشد. این زایحه تداعی کننده پروفایل طبیعی این اسانس
می باشد.
اما در انواع پنیر پیتزا این پروفایل متفاوت می باشد. این پروفایل در پنیر پیتزا ترش و تیز می باشد که جایگیزینی مناسب می باشد.
در انواع بسیتنی اسانس کره بیشتر دارای پروفایل شیرین و گرم می باشد اما قدرت آن نسبت به انواع کره کمتر می باشد.                </p>
                
                <h2>کاربرد در کیک و شیرینی</h2>
                <p>

در صنعت شیرینی و کیک پروفایل این اسانس باز هم تغییر می کند. این پروفایل دارای گرمی و شیرینی بیشتری می باشد. اما تفاوت
اصلی در ساختار طراحی آن می باشد. با توجه به محصولات مختلف در این صنعت، مقاومت حرارتی نیز اهمیت پیدا می کند.
به این شکل که به ترتیب برای بیسکویت، کیک، شیرینی و کلوچه مقاومت حرارتی به نسبت کم می شود. به طور کلی هر چه
محصولات آردی نرم تر باشند یعنی آب بیشتری خواهند داشت و به همین دلیل درجه حرارت پخت آن نیز کمتر می شود.                </p>
    
                <h2>اهمیت اسانس کره در صنایع غذایی</h2>
                <p>
در دیگر بخش تولیدات اسنسن کره در تولید انواع تافی کاربرد دارد. در این بخش با توجه به بالا بودن حرارت، اسانس با مقاومت
حرارتی مطلوب است. این اسانس دارای پروفایل گرم و شیرین است.
به این ترتیب با توجه به توضیحات بالا، اسانس کره در صنعت بسیار پر کاربد است و تنوع رایحه و پروفایل بالایی دارد. که این امر
نیاز به دانش مورد یاز برای طراحی مناسب برای تمامی محصولات می باشد.
صنایع کیمیاگر آریان اسانس با به کار گیری دانش روز و بهرمندی از تجربه طعم سازان مطرح دنیا، توانسته نیاز های صنایع غذایی
تنا حدود زیادی برطرف کند. در این راه با همکاری با بزرگترین شرکت های تولید کننده مواد اولیه، در جهت بالا بردن کیفیت
محصولات توانسته جایگاه ارزشمندی در بین مشتریان ایجاد کند.                </p>
            </div>
        )
    },
    { 
        id: 7, 
        img: p7, 
        title: 'اسانس شیر کندانسه', 
        description: 'خرید اسانس شیر کندانسه با رایحه و طعم طبیعی، مناسب برای صنایع غذایی و شیرینی‌پزی.',
        metaDescription: "اسانس شیر کندانسه مناسب برای صنایع غذایی، شیرینی‌پزی و شکلات‌سازی. خرید اسانس شیر کندانسه با کیفیت بالا و رایحه دلپذیر و مقاوم در برابر حرارت.",
        body: (
            <div class="description-box">
            
                <p>

اسانس شیرکندانسه یکی از اسانس های خاص و پرمصرف در صنایع غذایی شناخته می شود. این اسانس با توجه به مصارف مختلف
و استانداردهای متفاوت، دارای تنوع زیادی در بین مشتریان می باشد.
اسانس شیرکندانسه در انواع مختلف صنایع غذایی از لبنی و شیرینی و شکلات تا انواع تافی و کلوچه ها کاربرد فراوانی دارد.
این اسانس با توجه به نوع پروفایل تولیدی، شامل اسانس شیر تازه و شیر پخته نیز می باشد.
به طور کلی اسانس های شیر دارای پروفایل شیرین و گرم می باشد و از همین روی در صنایع مختلف اسانس پرطرف دارای
محسوب می شود.                </p>
                
                <h2>کاربرد در صنایع شیرینی و شکلات</h2>
                <p>
اسانس شیرکندانسه در صنایع شیرینی و شکلات مصرف گسترده تری دارد. این اسانس به طور عمدعه در تولید کیک تی تاپ و
انواع فیلر کاربرد گسترده تری دارد.
اما با توجه به نوع حلالیت آن کاربرد بالایی در انواع شکلات و تافی نیز دارد. البته برای انواع تافی شیرپخته طعم محبوب تری
محسوب می شود و کاربرد بیشتری دارد.                </p>
                
                <h2>کاربرد در صنایع لبنی</h2>
                <p>

در صنایع لبنی، اسانس شیر کندانسه به طور عمده به عنوان طعم دهنده مکمل در انواع شیر پاستوریزه کاربرد دارد. این اسانس
شیرینی و گرمی مطبوعی بع محصول می دهد.                </p>
                
                <h2>ویژگی‌ها و مزایا</h2>
                <p>

استفاده از شیر تازه و شیر پخته در صنایع مختلف بستگی به ذائقه مصرف کننده دارد. اساسن شیر تازه از شیرینی و گرمی کمتری
برخوردار بوده و معمولا در صنایع لبنی و کیک و کلوچه مورد مصرف قرار می گیرد. اما اسانس شیر پخته دارای عطر و طعم
بیشتری بوده و معمولا در انواع تافی و شکلات مورد استفاده دارد تا باعث هر چه بیشتر شده عطر و طعم شود.
مقاومت حراراتی اسانس شیر نسبتا بالا بوده اما با استفاده از حلال های مختلف می توان این میزان از مقاومت را بسته به نوع
محصول تنظیم کرد. 
               </p>
               
            </div>
        )
    }
];

export function Mahsulat  ()  {
    const { isDarkMode } = useTheme(); // استفاده از context
  const [visibleReviews, setVisibleReviews] = useState([]);

  useEffect(() => {
    // تنظیم تم بر اساس isDarkMode
    if (isDarkMode) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
  }} ,[isDarkMode]); 
    const { productId } = useParams();
    const product = products.find((p) => p.id === parseInt(productId));

    if (!product) {
        return <h2>محصول یافت نشد</h2>;
    }

    return (
        <div className="product-detail">
            <Helmet>
                <title>{product.title}</title>
                <meta name="description" content={product.metaDescription || product.description} />
                <meta name="keywords" content="اسانس کره، طعم‌دهنده کره، اسانس شیر کندانسه، صنایع غذایی، خرید اسانس" />
            </Helmet>
            <img src={product.img} alt={product.title} />
            <h1>{product.title}</h1>
            <div>{product.body}</div>
        </div>
    );
};

export default Mahsulat;
